nav.vertical ul li a.active {
  font-family: 'OpenSans-Bold', 'Open Sans Bold', 'Open Sans';
  font-weight: 700;
  font-size: 16px;
  background-color: #9bcbeb;
  border-left: #1b365d 5px solid;
}

.ui.dropdown .menu {
  min-width: max-content !important;
  left: auto !important;
  right: 0 !important;
}

.ui.form .field.error .ui.dropdown.interactive-template {
  border: 2px solid #da291c !important;
  background: #d9e1e2;
}

.ui.form .field.error .ui.dropdown.interactive-template .text,
.ui.form .field.error .ui.dropdown.interactive-template .menu .item:hover,
.ui.form
  .fields.error
  .field
  .ui.dropdown.interactive-template
  .menu
  .item:hover,
.ui.form .field.error .ui.dropdown.interactive-template .menu .selected.item,
.ui.form
  .fields.error
  .field
  .ui.dropdown.interactive-template
  .menu
  .selected.item {
  background: #d9e1e2;
  color: black;
}
