.wrapper-pin {
  min-height: 50vh;
  display: flex;
  flex-direction: column;
}
.content {
  display: flex;
  flex: 1;
}
.columns {
  display: flex;
  flex: 1;
}
main {
  flex: 1;
  order: 2;
}
.sidebar-first {
  width: 20%;
  order: 1;
}
.sidebar-second {
  width: 20%;
  order: 3;
}
