/* 
  Rotate 
*/
/* Elevation (based on Google Material Design)*/
svg.icon {
  fill: currentColor;
}

svg.icon-chatbubble {
  width: 135px;
  height: 65px;
}

svg.icon-smallbiz,
svg.icon-subscription,
svg.icon-horticulture,
svg.icon-library,
svg.icon-contractor,
svg.icon-knifefork {
  width: 35px;
  height: 35px;
}

svg.icon-chatbubble,
svg.icon-smallbiz,
svg.icon-subscription,
svg.icon-horticulture,
svg.icon-library,
svg.icon-contractor,
svg.icon-knifefork,
svg.icon-paycalc {
  fill: #1b365d;
}

@media (prefers-color-scheme: dark) {
  .theme-follow-system svg.icon-chatbubble,
  .theme-follow-system svg.icon-smallbiz,
  .theme-follow-system svg.icon-subscription,
  .theme-follow-system svg.icon-horticulture,
  .theme-follow-system svg.icon-library,
  .theme-follow-system svg.icon-contractor,
  .theme-follow-system svg.icon-knifefork,
  .theme-follow-system svg.icon-paycalc {
    fill: #e1e1e1;
  }
}

svg.icon-paycalc {
  width: 30px;
  height: 35px;
}

.breadcrumbs {
  margin: 0 0 20px;
  padding-left: 0px;
}

@media screen and (max-width: 767px) {
  .breadcrumbs {
    padding-left: 0;
  }
}

#table-requestinfo a {
  color: #024ca2 !important;
  text-decoration: underline !important;
  cursor: pointer !important;
}

table#abnSearch tr:focus {
  outline: 3px dashed #9bcbeb;
  box-shadow: 0 0 0 3px #1b365d;
}

.breadcrumbs a.underline {
  text-decoration: underline !important;
}

nav.horizontal {
  overflow-x: hidden;
  white-space: nowrap;
}

.chat .message {
  margin-bottom: 15px !important;
}

@media screen and (max-width: 374px) {
  .overlay .footer ul li {
    padding: 0 8px;
  }
}

.overlay .header,
.site-header {
  display: flex;
  font-size: 14px;
}

.form-search.minimal.site-search input {
  line-height: normal;
}

.form-search.minimal.site-search .btn-primary:focus {
  border: 3px dashed #9bcbeb;
}

.overlay .footer {
  text-align: center;
}

/* Focus #100430 */
form.ui.osp-variant input:focus,
form.ui.osp-variant input[type='text']:focus,
form.ui.osp-variant select:focus,
form.ui.osp-variant .field .ui.selection.active.dropdown,
form.ui.osp-variant .field .ui.selection.active.dropdown .menu {
  outline: 3px dashed #9bcbeb;
  box-shadow: 0 0 0px 3px #1b365d;
  /* Services Australia:
  outline: 3px solid #9364de;
  outline-offset: 2px;
  box-shadow: 0 0 0 2px #fff;*/
}

form.ui.osp-variant .field .ui.selection.active.dropdown .menu .item:hover,
form.ui.osp-variant .field .ui.selection.active.dropdown .menu .item:focus,
form.ui.osp-variant .field .ui.selection.active.dropdown .menu .item.selected,
.auto-complete-results > div,
.auto-complete-results > span {
  background-color: #fff;
  font-weight: bold;
}
