#aspnetForm {
  margin: 0;
}

/* @font-face {
  font-family: 'SohoStd';
  src: url('../../../src/assets/css/Workplace/SohoStd-Regular.eot');
  src: url('../../../src/assets/css/Workplace/SohoStd-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../../../src/assets/css/Workplace/SohoStd-Regular.woff') format('woff'),
    url('../../../src/assets/css/Workplace/SohoStd-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../../../src/assets/css/Workplace/SohoStd-Regular.ttf')
      format('truetype'),
    url('../../../src/assets/css/Workplace/SohoStd-Regular.eot?#iefix')
      format('embedded-opentype');
}
@font-face {
  font-family: 'avenri-light';
  src: url('../../../src/assets/css/Workplace/AvenirLTW01-35Light.eot');
  src: url('../../../src/assets/css/Workplace/AvenirLTW01-35Light.eot?#iefix')
      format('embedded-opentype'),
    url('../../../src/assets/css/Workplace/AvenirLTW01-35Light.woff')
      format('woff'),
    url('../../../src/assets/css/Workplace/AvenirLTW01-35Light.ttf')
      format('truetype');
}
@font-face {
  font-family: 'avenri-roman';
  src: url('../../../src/assets/css/Workplace/AvenirLTW01-55Roman.eot');
  src: url('../../../src/assets/css/Workplace/AvenirLTW01-55Roman.eot?#iefix')
      format('embedded-opentype'),
    url('../../../src/assets/css/Workplace/AvenirLTW01-55Roman.woff')
      format('woff'),
    url('../../../src/assets/css/Workplace/AvenirLTW01-55Roman.ttf')
      format('truetype');
}
@font-face {
  font-family: 'avenri-heavy';
  src: url('../../../src/assets/css/Workplace/AvenirLTW01-85Heavy.eot');
  src: url('../../../src/assets/css/Workplace/AvenirLTW01-85Heavy.eot?#iefix')
      format('embedded-opentype'),
    url('../../../src/assets/css/Workplace/AvenirLTW01-85Heavy.woff')
      format('woff'),
    url('../../../src/assets/css/Workplace/AvenirLTW01-85Heavy.ttf')
      format('truetype');
}
@font-face {
  font-family: 'avenri-black';
  src: url('../../../src/assets/css/Workplace/AvenirLTW01-95Black.eot');
  src: url('../../../src/assets/css/Workplace/AvenirLTW01-95Black.eot?#iefix')
      format('embedded-opentype'),
    url('../../../src/assets/css/Workplace/AvenirLTW01-95Black.woff')
      format('woff'),
    url('../../../src/assets/css/Workplace/AvenirLTW01-95Black.ttf')
      format('truetype');
}
@font-face {
  font-family: 'helvetica-bd';
  src: url('../../../src/assets/css/Workplace/HelveticaNeueeTextW01-Bd.eot');
  src: url('../../../src/assets/css/Workplace/HelveticaNeueeTextW01-Bd.eot?#iefix')
      format('embedded-opentype'),
    url('../../../src/assets/css/Workplace/HelveticaNeueeTextW01-Bd.woff')
      format('woff'),
    url('../../../src/assets/css/Workplace/HelveticaNeueeTextW01-Bd.ttf')
      format('truetype');
}
@font-face {
  font-family: 'helvetica-rg';
  src: url('../../../src/assets/css/Workplace/HelveticaNeueeTextW01-Rg.eot');
  src: url('../../../src/assets/css/Workplace/HelveticaNeueeTextW01-Rg.eot?#iefix')
      format('embedded-opentype'),
    url('../../../src/assets/css/Workplace/HelveticaNeueeTextW01-Rg.woff')
      format('woff'),
    url('../../../src/assets/css/Workplace/HelveticaNeueeTextW01-Rg.ttf')
      format('truetype');
} */
[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none;
}

.hide {
  display: none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

#app html,
#app body {
  margin: 0;
  padding: 0;
  width: auto;
}

#app h1,
#app h2,
#app h3,
#app h4,
#app h5,
#app h6,
#app blockquote,
#app pre,
#app abbr,
#app acronym,
#app address,
#app cite,
#app code,
#app del,
#app dfn,
#app em,
#app img,
#app q,
#app s,
#app samp,
#app small,
#app strike,
#app sub,
#app sup,
#app tt,
#app var,
#app dd,
#app dl,
#app dt,
#app li,
#app ol,
#app ul,
#app fieldset,
#app form,
#app label,
#app legend,
#app table,
#app caption,
#app tbody,
#app tfoot,
#app thead,
#app tr,
#app th,
#app td {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: normal;
  font-style: normal;
  font-size: 100%;
  line-height: 1;
  font-family: inherit;
}

#app table {
  border-collapse: collapse;
  border-spacing: 0;
}

#app ol,
#app ul {
  list-style: none;
}
#app .modal ul {
  margin: 1em 0;
  padding: 4px 30px;
}
#app .modal ul li {
  padding: 5px 0;
  list-style: initial;
  list-style-image: url('data:image/svg+xml;base64,JTNDc3ZnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zycgd2lkdGg9JzgnIGhlaWdodD0nNycgdmlld0JveD0nMCAwIDggNyclM0UlM0NyZWN0IGNsYXNzPSdjbHMtMScgeT0nMScgd2lkdGg9JzgnIGhlaWdodD0nMScvJTNFJTNDL3N2ZyUzRQ==');
}

#app q:before,
#app q:after,
#app blockquote:before,
#app blockquote:after {
  content: '';
}

#app html {
  overflow-y: auto;
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

#app article,
#app aside,
#app details,
#app figcaption,
#app figure,
#app footer,
#app header,
#app hgroup,
#app nav,
#app section {
  display: block;
}

#app audio,
#app canvas,
#app video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

#app audio:not([controls]) {
  display: none;
}

#app sub,
#app sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

#app sup {
  top: -0.5em;
}

#app sub {
  bottom: -0.25em;
}

#app img {
  border: 0;
  -ms-interpolation-mode: bicubic;
}

#app textarea {
  overflow: auto;
  vertical-align: top;
}

#app li {
  list-style: none;
}

#app {
  max-width: 1120px;
  margin-left: auto;
  margin-right: auto;
  text-rendering: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  height: 100%;
}

#app .tooltip {
  position: absolute;
  z-index: 1030;
  display: block;
  font-size: 11px;
  line-height: 1.4;
  opacity: 0;
  filter: alpha(opacity=0);
  visibility: visible;
}

#app .tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

#app .tooltip-inner {
  max-width: 200px;
  padding: 8px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #000;
  border-radius: 4px;
}

#app .informationPanel {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: none;
  /* background-image: url("../../../src/assets/images/bg_overlay_1px.png"); */
  background: rgba(0, 0, 0, 0.75);
  z-index: 9999;
}

#app .informationPanel .informationPanelMiddle .closeInfoPanel {
  position: absolute;
  display: block;
  top: 12px;
  right: 0;
  margin-right: 24px;
  text-indent: -1000px;
  overflow: hidden;
  background-image: url('../../../src/assets/images/button_close.png');
  background-repeat: no-repeat;
  background-position: 0 0;
  width: 32px;
  height: 32px;
}

#app .informationPanel .informationPanelMiddle .closeInfoPanel:hover,
#app .informationPanel .informationPanelMiddle .closeInfoPanel:focus {
  background-image: url('../../../src/assets/images/button_close.png');
  background-position: 0 -32px;
}

#app .informationPanel .informationPanelMiddle .featurePanelInfo {
  z-index: 11;
  position: absolute;
  width: 440px;
  max-width: 90%;
  right: 0;
  left: 0;
  top: 10%;
  margin: auto;
  background-color: #242526;
}

#app .informationPanel .informationPanelMiddle .featurePanelInfo p {
  color: white;
  padding: 24px;
}

#app .informationPanel .informationPanelMiddle .featurePanelInfo p p {
  padding: 0;
}

#app .informationPanel .informationPanelMiddle .featurePanelInfo p p + p {
  padding-top: 12px;
}

#app .informationPanel .informationPanelMiddle .featurePanelInfo p ul + p {
  padding-top: 12px;
}

#app .informationPanel .informationPanelMiddle .featurePanelInfo a {
  color: white;
  border-bottom: 1px solid white;
}

#app .informationPanel .informationPanelMiddle .featurePanelInfo a:hover,
#app .informationPanel .informationPanelMiddle .featurePanelInfo a:focus {
  border-bottom: 1px dashed white;
}

#app
  .informationPanel
  .informationPanelMiddle
  .featurePanelInfo
  a.closeInfoPanel {
  border-bottom: 0;
}

#app
  .informationPanel
  .informationPanelMiddle
  .featurePanelInfo
  a.closeInfoPanel:hover,
#app
  .informationPanel
  .informationPanelMiddle
  .featurePanelInfo
  a.closeInfoPanel:focus {
  border-bottom: 0;
}

#app .informationPanel .informationPanelMiddle .featurePanelInfo h2 {
  color: white;
  width: auto;
  padding: 17px 56px 17px 24px;
  font-size: 1em;
  line-height: 1.25em;
  text-transform: uppercase;
  font-weight: bold;
}

#app .informationPanel .informationPanelMiddle .featurePanelInfo h2.blue {
  background-color: #1b365d;
}

#app .informationPanel .informationPanelMiddle .featurePanelInfo h2.orange {
  background-color: #d24205;
}

#app .informationPanel .informationPanelMiddle .featurePanelInfo p {
  padding: 24px;
  font-size: 0.875em;
  line-height: 1.25em;
  color: white;
}

#app .informationPanel .informationPanelMiddle .featurePanelInfo p p {
  font-size: 1em;
}

#app .informationPanel .informationPanelMiddle .featurePanelInfo p ul {
  margin-top: 14px;
}

#app .informationPanel .informationPanelMiddle .featurePanelInfo p ul li {
  color: white;
}

#app .informationPanel .informationPanelMiddle .downloadPanelInfo {
  z-index: 11;
  position: absolute;
  width: 460px;
  right: 0;
  left: 0;
  top: 10%;
  margin: auto;
  background-color: #242526;
  color: #9c9c9c;
}

#app .informationPanel .informationPanelMiddle .downloadPanelInfo h3 {
  background-color: #0082c0;
  color: white;
  width: auto;
  padding: 17px 56px 17px 24px;
  font-size: 1em;
  line-height: 1.25em;
  text-transform: uppercase;
  font-weight: bold;
}

#app .informationPanel .informationPanelMiddle .downloadPanelInfo p {
  padding: 24px;
  font-size: 0.875em;
  line-height: 1.25em;
}

#app .informationPanel .informationPanelMiddle .downloadPanelInfo .textLinks {
  margin: 10px 13px 10px 17px;
  padding: 0;
  border-top: 1px dashed #434648;
  border-bottom: 1px dashed #434648;
}

#app
  .informationPanel
  .informationPanelMiddle
  .downloadPanelInfo
  .textLinks.last
  li {
  border-bottom: 0;
}

#app
  .informationPanel
  .informationPanelMiddle
  .downloadPanelInfo
  .textLinks
  li {
  border: 0;
  border-bottom: 1px dashed #434648;
  color: white;
  margin: 0;
  padding: 0;
  padding-top: 6px;
  padding-bottom: 6px;
  list-style: none;
}

#app
  .informationPanel
  .informationPanelMiddle
  .downloadPanelInfo
  .textLinks
  li
  .bundle {
  float: right;
}

#app
  .informationPanel
  .informationPanelMiddle
  .downloadPanelInfo
  .textLinks
  li
  p {
  padding: 0;
}

#app
  .informationPanel
  .informationPanelMiddle
  .downloadPanelInfo
  .textLinks
  li
  a {
  color: white;
  font-size: 0.875em;
  line-height: 1.143em;
  font-family: 'oxygenbold', Arial;
  text-transform: uppercase;
}

#app
  .informationPanel
  .informationPanelMiddle
  .downloadPanelInfo
  .textLinks
  li
  a
  span {
  color: white;
}

#app
  .informationPanel
  .informationPanelMiddle
  .downloadPanelInfo
  .textLinks
  li
  a:hover {
  text-decoration: underline;
}

#app
  .informationPanel
  .informationPanelMiddle
  .downloadPanelInfo
  .textLinks
  li
  a:focus {
  text-decoration: underline;
}

#app
  .informationPanel
  .informationPanelMiddle
  .downloadPanelInfo
  .textLinks
  .linkContent:last-child {
  margin: 0;
  border: 0;
}

#app .tooltip {
  font-size: 0.7em;
  z-index: 9;
}

#app .tooltip.in {
  opacity: 0.8;
  filter: alpha(opacity=80);
}

#app .tooltip.top {
  padding: 5px 0;
  margin-top: -3px;
}

#app .tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-top-color: #000;
  border-width: 5px 5px 0;
}

#app a.glossaryLink {
  color: #231f20;
  text-decoration: underline;
}

#app .preloader,
#app .loader {
  width: 100%;
  height: 100%;
  color: #04263f;
  text-align: center;
}

#app .preloader .loaderTable,
#app .loader .loaderTable {
  display: table;
  width: 100%;
  height: 100%;
}

#app .preloader .loaderTable .loaderCell,
#app .loader .loaderTable .loaderCell {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

#app .preloader .loaderTable .loaderCell .loaderText,
#app .loader .loaderTable .loaderCell .loaderText {
  display: inline-block;
  background-image: url('../../../src/assets/images/ajax-loader.gif');
  background-position: left center;
  background-repeat: no-repeat;
  text-indent: 26px;
  margin: 10px;
}

#app .animateButtonTransitions {
  transition: background-color 333ms, color 333ms;
}

#app .animateTextEntrances {
  animation: fadeIn 333ms;
}

#app .animateButtonClicks {
  transition: all 333ms;
}

#app .animateButtonClicks:active {
  transform: scale(0.9, 0.9);
}

#app .layout {
  position: relative;
  margin: 0 auto 0 auto;
  height: 100%;
}

#app .layout .contentMenu {
  z-index: 20;
  height: auto;
  position: absolute;
  display: none;
  left: 0;
  top: 3em;
  width: 350px;
}

#app .layout .contentMenu.open {
  left: 4px;
  display: block;
  top: 85px;
}

#app .layout .contentMenu .quickMenuListArea {
  height: 100%;
  background-color: #282a29;
  border-right: 1px solid #1f2120;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.33);
  border-top: 1px solid #1f2120;
  margin-top: -1px;
}

#app .layout .contentMenu .quickMenuListArea ul {
  width: 100%;
  border-right: 1px solid #1f2120;
}

#app .layout .contentMenu .quickMenuListArea ul li {
  border-bottom: 1px solid #1f2120;
}

#app .layout .contentAll {
  z-index: 10;
  width: 100%;
  height: 100%;
  left: 0;
  /* border-left: 1px solid #231f20;
              border-right: 1px solid #231f20;
              border-bottom: 1px solid #231f20 */
  border: 4px solid #9bcbeb;
}

#app .layout .contentAll.open {
  left: 0;
}

#app .layout .contentAll .contentMask {
  z-index: 10;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}

#app .disclaimerPrivacy {
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
}

#app .disclaimerPrivacy .header {
  animation: fadeIn 333ms;
  background-color: white;
  width: 100%;
}

#app .disclaimerPrivacy .header a {
  display: block;
  width: 100%;
  height: 90px;
}

#app .disclaimerPrivacy .header a img {
  width: auto;
  height: 100%;
}

#app .disclaimerPrivacy .content {
  animation: fadeIn 333ms;
  margin: 10px;
}

#app .disclaimerPrivacy .content .heading {
  color: #118286;
  text-transform: uppercase;
}

#app .disclaimerPrivacy .content h1 {
  color: #118286;
  text-transform: uppercase;
  font-size: 1.5em;
  line-height: 1em;
  /* font-family: 'SohoStd'; */
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 1em;
}

#app .disclaimerPrivacy .content h2 {
  color: #118286;
  text-transform: uppercase;
  font-size: 1.45em;
  line-height: 1em;
  /* font-family: 'SohoStd'; */
  font-weight: bold;
  margin-top: 1em;
  margin-bottom: 1em;
}

#app .disclaimerPrivacy .content h3 {
  color: #118286;
  text-transform: uppercase;
  font-size: 1.25em;
  line-height: 1.25em;
  /* font-family: 'SohoStd'; */
  margin-top: 0.7em;
  margin-bottom: 0.7em;
}

#app .disclaimerPrivacy .content p {
  font-size: 0.9em;
  line-height: 1.25em;
  color: #3d3f3e;
  margin-top: 1em;
}

#app .disclaimerPrivacy .content p .bold {
  font-weight: bold;
}

#app .disclaimerPrivacy .content p .italics {
  font-style: italic;
}

#app .disclaimerPrivacy .content ul {
  font-size: 0.9em;
  line-height: 1.25em;
  margin-top: 0.5em;
  color: #3d3f3e;
  list-style-position: outside;
  list-style-type: disc;
}

#app .disclaimerPrivacy .content ul li {
  color: #3d3f3e;
  margin-left: 2em;
  margin-bottom: 0.5em;
}

#app .disclaimerPrivacy .footer .bottom .links li {
  height: 100% !important;
}

#app .disclaimerPrivacy .footer .bottom .links li a {
  height: 6.25em !important;
  line-height: 6.25em !important;
}

#app .barClicker {
  display: table;
  text-align: left;
  width: 100%;
  height: 5em;
  margin: 0;
  padding: 0;
  cursor: pointer;
  text-decoration: none;
}

#app .barClicker .left {
  display: table-cell;
  vertical-align: middle;
  width: 3em;
  height: 100%;
  background-color: #1b365d;
  transition: background-color 333ms, color 333ms;
}

#app .barClicker .left input {
  position: absolute;
  left: -10000px;
}

#app .barClicker .left .imgContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

#app .barClicker .left .imgContainer img {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: auto;
  filter: sepia(100%) saturate(300%) brightness(70%) hue-rotate(180deg);
}

#app .barClicker .left .selection {
  display: inline-block;
  font-size: 0;
  width: 0;
  height: 0;
  line-height: 0;
  overflow: hidden;
  margin: 0;
}

#app .barClicker .center {
  margin: 0;
  padding: 10px 0 10px 0;
  display: table-cell;
  vertical-align: middle;
  color: white;
  background-color: #1b365d;
  transition: background-color 333ms, color 333ms;
  /* font-family: 'avenri-light'; */
  text-align: left;
  line-height: 1.2em;
}

#app .barClicker .center div,
#app .barClicker .center span {
  line-height: 1.2em;
}

#app .barClicker .center.time {
  background-repeat: no-repeat;
  background-position: 4% center;
  background-size: 60px;
}

#app .barClicker .center.time.time5 {
  background-image: url('../../../src/assets/images/button/clear/time_5.png');
}

#app .barClicker .center.time.time10 {
  background-image: url('../../../src/assets/images/button/clear/time_10.png');
}

#app .barClicker .center.time.time15 {
  background-image: url('../../../src/assets/images/button/clear/time_15.png');
}

#app .barClicker .center .startTime {
  float: left;
  /* font-family: 'helvetica-rg'; */
  font-size: 0.8em;
  line-height: 1.2em;
  padding-top: 0.6em;
  text-align: left;
  padding-left: 80px;
  width: 42%;
}

#app .barClicker .center .startText {
  font-size: 1.4em;
  line-height: 2.1em;
  float: right;
  text-align: right;
  padding-right: 24px;
}

#app .barClicker .right {
  display: table-cell;
  vertical-align: middle;
  width: 5em;
  height: 100%;
  background-color: #9bcbeb;
  transition: background-color 333ms, color 333ms;
}

#app .barClicker .right .imgContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

#app .barClicker .right .imgContainer img {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: auto;
}

#app .barClicker.centerOnly .left {
  display: none;
}

#app .barClicker.centerOnly .right {
  display: none;
}

#app .barClicker.rightOnly .left {
  display: none;
}

#app .barClicker.leftOnly .right {
  display: none;
}

#app .barClicker.continue {
  text-transform: uppercase;
}

#app .barClicker.continue .left {
  display: none;
}

#app .barClicker.feedback {
  text-transform: uppercase;
}

#app .barClicker.feedback .left {
  display: none;
}

#app .barClicker.summaryButton {
  width: 20em;
  height: 2.5em;
  margin-top: 15px;
}

#app .barClicker.summaryButton .center {
  text-align: left;
  background-color: #6a6a6a;
  padding: 8px 0 8px 0;
  padding-left: 1em;
  padding-right: 1em;
  /* font-family: 'avenri-roman'; */
  text-transform: uppercase;
  font-size: 1em;
}

#app .barClicker.summaryButton .right {
  width: 2.5em;
  background-color: #989898;
  background-repeat: no-repeat;
}

#app .barClicker.summaryButton.clickable:hover .center,
#app .barClicker.summaryButton.clickable:focus .center {
  background-color: #606060;
}

#app .barClicker.summaryButton.clickable:hover .left,
#app .barClicker.summaryButton.clickable:focus .left,
#app .barClicker.summaryButton.clickable:hover .right,
#app .barClicker.summaryButton.clickable:focus .right {
  background-color: #8e8e8e;
}

#app .barClicker.summaryButton.clickable:active .center {
  background-color: #606060;
}

#app .barClicker.summaryButton.clickable:active .left,
#app .barClicker.summaryButton.clickable:active .right {
  background-color: #8e8e8e;
}

#app .barClicker.option {
  height: 4em;
}

#app .barClicker.option .right {
  display: none;
}

#app .barClicker.option .left {
  width: 4em;
  background-color: #9bcbeb;
  border: 3px solid #9bcbeb;
}

#app .barClicker.option .left.selected {
  background-color: #9bcbeb;
}

#app .barClicker.option .center {
  /* font-family: 'helvetica-rg'; */
  line-height: 1.2em;
}

#app .barClicker.option .center a.glossaryLink {
  color: white;
}

#app .barClicker.option .center .textContainer {
  padding-left: 1em;
  padding-right: 1em;
}

#app .barClicker.option .center .textContainer a {
  text-decoration: underline;
  color: white;
}

#app .barClicker.option.clickable:hover .center,
#app .barClicker.option.clickable:focus .center {
  background-color: #04263f;
}

#app .barClicker.option.clickable:active .center {
  background-color: #04263f;
}

#app .barClicker.link .left {
  display: none;
}

#app .barClicker.link .center {
  color: #282a29;
  background-color: #c6c6bc;
}

#app .barClicker.link .right {
  background-color: #fff;
}

#app .barClicker.link.clickable:hover .center,
#app .barClicker.link.clickable:focus .center {
  background-color: #04263f;
}

#app .barClicker.link.clickable:active .center {
  background-color: #04263f;
}

#app #quickMenu button {
  height: 3em;
  background-color: #1b365d;
  font-size: 1.2em;
  padding: 0 5%;
  width: 100%;
  border-bottom: 1px solid #1f2120;
  color: #fff;
  text-align: left;
  cursor: pointer;
}

#app #quickMenu button:focus,
#app #quickMenu button:hover {
  background-color: #9bcbeb !important;
  color: #000000;
}

#app .barClicker.clickable {
  cursor: pointer;
}

#app .barClicker.clickable .left,
#app .barClicker.clickable .right {
  border: 3px solid #9bcbeb;
}

#app .barClicker.clickable:focus .center,
#app .barClicker.clickable:hover .center {
  background-color: #04263f;
}

#app .barClicker.clickable:focus .left,
#app .barClicker.clickable:hover .left,
#app .barClicker.clickable:focus .right,
#app .barClicker.clickable:hover .right {
  background-color: #ffffff;
}

#app .barClicker.clickable:active .center {
  background-color: #04263f;
}

#app .barClicker.nav {
  height: 3em;
  width: 40%;
  min-width: 10em;
}

#app .barClicker.nav .center {
  /* font-family: 'avenri-roman'; */
  font-size: 1.2em;
}

#app .barClicker.nav .center div {
  padding-left: 1em;
  padding-right: 1em;
}

#app .barClicker.nav.leftOnly {
  float: left;
}

#app .barClicker.nav.rightOnly {
  float: right;
}

#app .barClicker.nav.rightOnly .center {
  text-align: right;
}

#app .barClicker.nav .left {
  width: 3em;
  background-color: #9bcbeb;
}

#app .barClicker.nav .right {
  width: 3em;
}

#app .barClicker.nav.disabled .center {
  background-color: #7d7d7d;
  color: white;
}

#app .barClicker.nav.disabled .left,
#app .barClicker.nav.disabled .right {
  background-color: #999;
  color: white;
}

#app .barHeaderIntro,
#app .barHeaderQuestions,
#app .barHeaderSummary {
  background-color: #1b365d;
  display: grid;
  grid-template-columns: 84px 321px 1fr 84px;
  grid-template-rows: 80px;
}

@media (max-width: 1024px) {
  #app .barHeaderIntro,
  #app .barHeaderQuestions,
  #app .barHeaderSummary {
    grid-template-columns: 84px 1fr 84px;
  }
}

@media (max-width: 600px) {
  #app .barHeaderIntro,
  #app .barHeaderQuestions,
  #app .barHeaderSummary {
    grid-template-columns: 52px 1fr 52px;
  }
}

@media (max-width: 500px) {
  #app .barHeaderIntro .title,
  #app .barHeaderQuestions .title,
  #app .barHeaderSummary .title {
    grid-column-start: 2;
    grid-column-end: 4;
  }
}

#app .barHeaderIntro .quickMenuArea,
#app .barHeaderQuestions .quickMenuArea,
#app .barHeaderSummary .quickMenuArea {
  width: 100%;
  height: 80px;
}

#app .barHeaderIntro .quickMenuArea .screenReaderPrompt,
#app .barHeaderQuestions .quickMenuArea .screenReaderPrompt,
#app .barHeaderSummary .quickMenuArea .screenReaderPrompt {
  height: 0;
  line-height: 0;
  overflow: hidden;
  margin: 0;
}

#app .barHeaderIntro .quickMenuArea .quickMenu,
#app .barHeaderQuestions .quickMenuArea .quickMenu,
#app .barHeaderSummary .quickMenuArea .quickMenu {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #9bcbeb;
  /* font-family: 'avenri-roman'; */
  transition: background-color 333ms, color 333ms;
}

#app .barHeaderIntro .quickMenuArea .quickMenu img,
#app .barHeaderQuestions .quickMenuArea .quickMenu img,
#app .barHeaderSummary .quickMenuArea .quickMenu img {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
}

#app .barHeaderIntro .quickMenuArea .quickMenu span,
#app .barHeaderQuestions .quickMenuArea .quickMenu span,
#app .barHeaderSummary .quickMenuArea .quickMenu span {
  display: block;
  width: 0;
  height: 0;
  text-indent: -10000px;
}

#app .barHeaderIntro .quickMenuArea .quickMenu:hover,
#app .barHeaderQuestions .quickMenuArea .quickMenu:hover,
#app .barHeaderSummary .quickMenuArea .quickMenu:hover,
#app .barHeaderIntro .quickMenuArea .quickMenu:focus,
#app .barHeaderQuestions .quickMenuArea .quickMenu:focus,
#app .barHeaderSummary .quickMenuArea .quickMenu:focus {
  background-color: #ffffff;
}

#app .barHeaderIntro .quickMenuArea .quickMenu:active,
#app .barHeaderQuestions .quickMenuArea .quickMenu:active,
#app .barHeaderSummary .quickMenuArea .quickMenu:active {
  background-color: #04263f;
}

#app .barHeaderIntro .quickMenuArea .quickMenu:focus,
#app .barHeaderQuestions .quickMenuArea .quickMenu:focus,
#app .barHeaderSummary .quickMenuArea .quickMenu:focus {
  margin-top: -1px;
  margin-left: -1px;
  border: 1px solid white;
}

#app .barHeaderIntro .title h2.titleContent,
#app .barHeaderQuestions .title h2.titleContent,
#app .barHeaderSummary .title h2.titleContent {
  font-size: 2em;
  color: #9bcbeb;
  font-weight: 600;
  letter-spacing: 0.6px;
  line-height: 80px;
  text-align: center;
}

@media (min-width: 600px) and (max-width: 1024px) {
  #app .barHeaderIntro .title h2.titleContent,
  #app .barHeaderQuestions .title h2.titleContent,
  #app .barHeaderSummary .title h2.titleContent {
    text-align: left;
    padding-left: 22px;
  }
}

@media (max-width: 599px) {
  #app .barHeaderIntro .title h2.titleContent,
  #app .barHeaderQuestions .title h2.titleContent,
  #app .barHeaderSummary .title h2.titleContent {
    font-size: 1.8em;
  }
}

#app .barHeaderIntro .title h2.titleContent span,
#app .barHeaderQuestions .title h2.titleContent span,
#app .barHeaderSummary .title h2.titleContent span {
  color: #ffd38c;
  font-style: italic;
}

#app .barHeaderIntro .progressArea,
#app .barHeaderQuestions .progressArea,
#app .barHeaderSummary .progressArea {
  margin: auto 0;
}

@media (max-width: 1024px) {
  #app .barHeaderIntro .progressArea,
  #app .barHeaderQuestions .progressArea,
  #app .barHeaderSummary .progressArea {
    grid-column-start: 2;
    padding: 16px 16px 16px 20px;
  }
}

@media (max-width: 599px) {
  #app .barHeaderIntro .progressArea,
  #app .barHeaderQuestions .progressArea,
  #app .barHeaderSummary .progressArea {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}

#app .barHeaderIntro .progressArea h3,
#app .barHeaderQuestions .progressArea h3,
#app .barHeaderSummary .progressArea h3 {
  color: #ffffff;
  font-size: 1.5em;
}

#app .barHeaderIntro .progressArea .progressBar .progressPoint,
#app .barHeaderQuestions .progressArea .progressBar .progressPoint,
#app .barHeaderSummary .progressArea .progressBar .progressPoint {
  display: inline-block;
  width: 100%;
  height: 15px;
  margin: 0;
}

#app .barHeaderIntro .progressArea .progressBar .progressPoint span,
#app .barHeaderQuestions .progressArea .progressBar .progressPoint span,
#app .barHeaderSummary .progressArea .progressBar .progressPoint span {
  display: inline-block;
  width: 100%;
  border-bottom: 5px solid #ddd;
}

#app .barHeaderIntro .progressArea .progressBar .progressPoint span.finished,
#app
  .barHeaderQuestions
  .progressArea
  .progressBar
  .progressPoint
  span.finished,
#app .barHeaderSummary .progressArea .progressBar .progressPoint span.finished {
  background-position: 0 -15px;
  border-bottom: 5px solid #ffd38c;
}

#app .barHeaderIntro .moduleIndex,
#app .barHeaderQuestions .moduleIndex,
#app .barHeaderSummary .moduleIndex {
  color: #9bcbeb;
  font-size: 2em;
  margin: auto;
}

#app .barHeaderIntro .nav,
#app .barHeaderQuestions .nav,
#app .barHeaderSummary .nav {
  position: absolute;
  top: 0;
  right: 1px;
  height: 100%;
}

#app .barHeaderIntro .nav ul,
#app .barHeaderQuestions .nav ul,
#app .barHeaderSummary .nav ul {
  width: 6.25em;
  overflow-x: hidden;
}

#app .barHeaderIntro .nav ul li,
#app .barHeaderQuestions .nav ul li,
#app .barHeaderSummary .nav ul li {
  width: 3.125em;
  height: 100%;
  overflow: hidden;
}

#app .barHeaderIntro .nav ul li a,
#app .barHeaderQuestions .nav ul li a,
#app .barHeaderSummary .nav ul li a {
  position: absolute;
  display: block;
  width: 50%;
  height: 100%;
  cursor: default;
  background-color: #3b3e3e;
  border-left: 1px solid white;
  transition: background-color 333ms, color 333ms;
}

#app .barHeaderIntro .nav ul li a img,
#app .barHeaderQuestions .nav ul li a img,
#app .barHeaderSummary .nav ul li a img {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
}

#app .barHeaderIntro .nav ul li a.right,
#app .barHeaderQuestions .nav ul li a.right,
#app .barHeaderSummary .nav ul li a.right {
  left: 3.125em;
}

#app .barHeaderIntro .nav ul li a.right:focus,
#app .barHeaderQuestions .nav ul li a.right:focus,
#app .barHeaderSummary .nav ul li a.right:focus {
  border-right: 0;
}

#app .barHeaderIntro .nav ul li a.enabled,
#app .barHeaderQuestions .nav ul li a.enabled,
#app .barHeaderSummary .nav ul li a.enabled {
  background-color: #817237;
  cursor: pointer;
}

#app .barHeaderIntro .nav ul li a.enabled:hover,
#app .barHeaderQuestions .nav ul li a.enabled:hover,
#app .barHeaderSummary .nav ul li a.enabled:hover,
#app .barHeaderIntro .nav ul li a.enabled:focus,
#app .barHeaderQuestions .nav ul li a.enabled:focus,
#app .barHeaderSummary .nav ul li a.enabled:focus {
  background-color: #736531;
}

#app .barHeaderIntro .nav ul li a.enabled:active,
#app .barHeaderQuestions .nav ul li a.enabled:active,
#app .barHeaderSummary .nav ul li a.enabled:active {
  background-color: #736531;
}

#app .barHeaderIntro .nav ul li a:focus,
#app .barHeaderQuestions .nav ul li a:focus,
#app .barHeaderSummary .nav ul li a:focus {
  margin-top: -1px;
  margin-right: -1px;
  border: 1px solid white;
}

#app .menu .header {
  width: 100%;
}

#app .menu .header a {
  display: block;
  background-color: white;
  width: 100%;
  height: 90px;
}

#app .menu .header a img {
  width: auto;
  height: 100%;
}

#app .menu .header h1 {
  /* font-family: 'SohoStd'; */
  color: #118286;
  margin-left: 10px;
  margin-right: 10px;
  display: block;
}

#app .menu .header h1 span {
  font-size: 1.18em;
  line-height: 1.1em;
  display: table-cell;
  height: 90px;
  height: 4.766em;
  vertical-align: middle;
}

#app .menu .header .srHide {
  position: relative;
  display: block;
  width: 0;
  height: 0;
  font-size: 0;
}

/*#app .menu .modules {
  padding: 24px 32px;
  display: grid;
  grid-template-columns: 40% 1fr;
  gap: 16px;
}*/

@media (max-width: 1024px) {
  #app .menu .modules {
    grid-template-columns: 1fr;
  }
}

#app .menu .modules .borderBox {
  box-sizing: border-box;
}

#app .menu .modules .contentBox {
  box-sizing: content-box;
}

/*#app .menu .modules .important {
  min-height: 25em; 
}*/
#app .menu .modules ul {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.5em;
  padding: 16px;
}

#app .menu .modules ul li a {
  display: block;
  height: 100%;
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 75px;
  border: 4px solid #9bcbeb;
  min-height: 80px;
  background-color: #ffffff;
  margin: 0;
  padding: 0;
}

#app .menu .modules ul li.finishedModule > a {
  background-color: #d9e1e2;
  border-color: #bfcecf;
  color: #557072;
  background-image: url('../../../src/assets/images/home/tick.png') !important;
  background-size: auto;
  background-position-x: 90%;
  background-position-y: center;
}

#app .menu .modules ul li a div {
  padding: 15px 75px 15px 15px;
  font-size: 1.2em;
  line-height: 1.2em;
  letter-spacing: 0.001em;
  word-wrap: break-word;
  font-weight: 600;
}

#app .menu .modules ul li a.module1 {
  background-image: url('../../../src/assets/images/home/module1.png');
}

#app .menu .modules ul li a.module2 {
  background-image: url('../../../src/assets/images/home/module2.png');
}

#app .menu .modules ul li a.module3 {
  background-image: url('../../../src/assets/images/home/module3.png');
}

#app .menu .modules ul li a.module4 {
  background-image: url('../../../src/assets/images/home/module4.png');
}

#app .menu .modules ul li a.module5 {
  background-image: url('../../../src/assets/images/home/module5.png');
}

#app .menu .modules ul li a.module6 {
  background-image: url('../../../src/assets/images/home/module6.png');
}

#app .menu .modules ul li a.module7 {
  background-image: url('../../../src/assets/images/home/module7.png');
}

#app .menu .modules ul li a.module8 {
  background-image: url('../../../src/assets/images/home/module8.png');
}

#app .menu .modules ul li a.resource {
  background-image: url('../../../src/assets/images/home/icon_resource.png');
}

#app .menu .modules ul li:hover a,
#app .menu .modules ul li:focus a {
  background-color: #9bcbeb;
}

#app .menu .modules ul li:active a {
  background-color: #9bcbeb;
}

#app .menu .modules .introText h2 {
  color: #1b365d;
  font-size: 2em;
  line-height: 1.2em;
  padding: 16px;
}

#app .menu .modules .introText a {
  color: #231f20;
  border-bottom: #231f20 dotted 1px;
}

#app .menu .modules .introText a:hover,
#app .menu .modules .introText a:focus {
  color: #1b365d;
  border-bottom: #1b365d dotted 1px;
}

#app .menu .modules .introText a.glossaryLink {
  border-bottom: 0;
}

#app .menu .modules .introText a.glossaryLink:hover,
#app .menu .modules .introText a.glossaryLink:focus {
  color: inherit;
  border-bottom: 0;
}

#app .menu .modules .introText .introBody {
  color: #231f20;
  /* font-family: 'helvetica-rg'; */
  font-size: 1.1em;
  line-height: 1.3em;
  padding: 16px;
}

#app .menu .modules .introText .introBody p {
  margin-bottom: 0.5em;
  line-height: 1.3em;
}

#app .menu .modules .introText .introBody ul {
  list-style: disc;
  margin-left: 2em;
  margin-bottom: 0.5em;
}

#app .intro {
  min-height: 35em;
  padding-bottom: 1px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: left 2em center;
  background-size: 25%;
}

#app .intro .content {
  animation: fadeIn 333ms;
  color: #231f20;
  text-align: left;
  line-height: 1.5em;
  /* margin: 50px 84px 64px 84px; */
  margin: 24px 32px;
  background-repeat: no-repeat;
}

#app .intro .content .contentTitle {
  animation: fadeIn 333ms;
  color: #1b365d;
  text-align: left;
  margin: 0 0 34px 0;
  line-height: 1.5em;
}

#app .intro .content .contentTitle h1 {
  color: #1b365d;
  font-size: 1.8em;
  line-height: 1.2em;
  width: 100%;
}

#app .intro .content .contentArea {
  margin-left: auto;
  width: 60%;
}

#app .intro .content .contentArea .completeText {
  margin-bottom: 12px;
  font-size: 1.4em;
  color: #1b365d;
}

#app .intro .content .contentArea .contentText {
  color: #231f20;
}

#app .intro .content .contentArea .contentText a.glossaryLink {
  border-bottom: 0;
}

#app .intro .content .contentArea .contentText a.glossaryLink:hover,
#app .intro .content .contentArea .contentText a.glossaryLink:focus {
  color: inherit;
  border-bottom: 0;
}

#app .intro .content .contentArea .contentText p,
#app .intro .content .contentArea .buttonArea .time p {
  font-size: 1em;
  line-height: 1.3em;
  margin-bottom: 0.5em;
}

#app .intro .content .contentArea .contentText ul {
  font-size: 1em;
  line-height: 1.3em;
  margin-bottom: 0.5em;
  margin-left: 0;
  margin-right: 40px;
  list-style: disc;
}

#app .intro .content .contentArea .contentText ul li {
  /* font-family: 'helvetica-rg'; */
  text-align: left;
  line-height: 1.3em;
  margin-bottom: 10px;
  margin-left: 1.5em;
  list-style: disc;
}

#app .intro.module1 {
  background-image: url('../../../src/assets/images/intro1.png');
}

#app .intro.module1 .content {
  background-position: 8% top;
}

#app .intro.module2 {
  background-image: url('../../../src/assets/images/intro2.png');
}

#app .intro.module3 {
  background-image: url('../../../src/assets/images/intro4.png');
}

#app .intro.module4 {
  background-image: url('../../../src/assets/images/intro3.png');
}

#app .intro.module5 {
  background-image: url('../../../src/assets/images/intro5.png');
}

#app .intro.module6 {
  background-image: url('../../../src/assets/images/intro7.png');
}

#app .intro.module7 {
  background-image: url('../../../src/assets/images/intro6.png');
}

#app .intro.module8 {
  background-image: url('../../../src/assets/images/intro8.png');
}

#app .question {
  min-height: 35em;
  padding-bottom: 1px;
  width: 100%;
}

#app .question .questionArea {
  animation: fadeIn 333ms;
  margin: 50px 84px 64px 84px;
}

#app .question .questionArea .imageArea {
  position: relative;
  min-height: 9em;
  width: 39%;
  display: inline-block;
  vertical-align: top;
}

#app .question .questionArea .imageArea .image {
  margin: 0;
  height: auto;
  width: 85%;
}

#app .question .questionArea .content {
  width: 60%;
  display: inline-block;
}

#app .question .questionArea .content .contentHolder {
  min-height: 400px;
  padding: 6px;
}

#app .question .questionArea .content ul {
  margin: 0 1px 0 1px;
}

#app .question .questionArea .content ul li {
  margin-bottom: 1px;
}

#app .question .questionArea .content ul li .barClicker.clickable:hover .center,
#app
  .question
  .questionArea
  .content
  ul
  li
  .barClicker.clickable:focus
  .center {
  background-color: #04263f;
}

#app .question .questionArea .content ul li .barClicker.clickable:hover .left,
#app .question .questionArea .content ul li .barClicker.clickable:focus .left,
#app .question .questionArea .content ul li .barClicker.clickable:hover .right,
#app .question .questionArea .content ul li .barClicker.clickable:focus .right {
  background-color: #ffffff;
}

#app
  .question
  .questionArea
  .content
  ul
  li
  .barClicker.clickable:active
  .center {
  background-color: #04263f;
}

#app
  .question
  .questionArea
  .content
  ul
  li.selectedOption
  .barClicker.clickable:hover
  .center,
#app
  .question
  .questionArea
  .content
  ul
  li.selectedOption
  .barClicker.clickable:focus
  .center {
  background-color: #04263f;
}

#app
  .question
  .questionArea
  .content
  ul
  li.selectedOption
  .barClicker.clickable:active
  .center {
  background-color: #04263f;
}

#app .question .questionArea .content .holder {
  width: auto;
  margin: 0 10px 20px 0;
}

#app .question .questionArea .content .holder .questionText {
  color: #231f20;
  line-height: 1.2em;
  text-align: left;
  width: 100%;
  /* font-family: 'helvetica-rg'; */
}

#app .question .questionArea .content .holder .questionText .bold {
  /* font-family: 'helvetica-bd'; */
}

#app .question .questionArea .content .holder .questionText .italics {
  font-style: italic;
}

#app .question .questionArea .content .holder .questionText p {
  margin-bottom: 0.4em;
  line-height: 1.2em;
}

#app
  .question
  .questionArea
  .content
  .holder
  .questionText
  p
  a.glossaryLink:hover,
#app
  .question
  .questionArea
  .content
  .holder
  .questionText
  p
  a.glossaryLink:focus {
  color: inherit;
  border-bottom: 0;
}

#app .question .questionArea .content .navButtons {
  margin-top: 2em;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 80px;
}

#app .question .questionArea .content .navButtons a.button {
  text-align: center;
}

#app .question .questionArea .content .feedbackArea {
  margin-top: 30px;
  color: #231f20;
  /* font-family: 'helvetica-rg'; */
}

#app .question .questionArea .content .feedbackArea .feedbackText {
  width: 100%;
  display: inline-block;
}

#app .question .questionArea .content .feedbackArea .feedbackText h1 {
  color: #1b365d;
  display: block;
  padding-left: 32px;
  width: auto;
  /* font-family: 'avenri-black'; */
  font-size: 1.4em;
  border-bottom: 0 solid #404241;
  margin-bottom: 10px;
  position: relative;
}

#app .question .questionArea .content .feedbackArea .feedbackText h1:before {
  content: ' ';
  display: block;
  width: 25px;
  height: 25px;
  position: absolute;
  top: -2px;
  left: 0;
  background-image: url('../../../src/assets/images/question_mark.png');
  background-position: left center;
  background-size: 25px 25px;
  background-repeat: no-repeat;
}

#app
  .question
  .questionArea
  .content
  .feedbackArea
  .feedbackText.correct
  h1:before {
  background-image: url('../../../src/assets/images/correct_small.png');
}

#app
  .question
  .questionArea
  .content
  .feedbackArea
  .feedbackText.incorrect
  h1:before {
  background-image: url('../../../src/assets/images/incorrect_small.png');
}

#app .question .questionArea .content .feedbackArea .feedbackText p {
  font-size: 1em;
  line-height: 1.3em;
  margin: 0 0 10px 0;
}

#app
  .question
  .questionArea
  .content
  .feedbackArea
  .feedbackText
  p
  a.glossaryLink {
  border-bottom: 0;
}

#app
  .question
  .questionArea
  .content
  .feedbackArea
  .feedbackText
  p
  a.glossaryLink:hover,
#app
  .question
  .questionArea
  .content
  .feedbackArea
  .feedbackText
  p
  a.glossaryLink:focus {
  color: inherit;
  border-bottom: 0;
}

#app .question .questionArea .content .feedbackArea .feedbackText p:last-child {
  margin-bottom: 0;
}

#app .question .questionArea .content .feedbackArea .feedbackText ul {
  margin: 0 10px 10px 0;
  font-size: 1em;
  line-height: 1.3em;
  list-style-type: disc;
  list-style-position: outside;
}

#app .question .questionArea .content .feedbackArea .feedbackText ul li {
  margin-left: 1.5em;
  list-style: disc;
  line-height: 1.3em;
}

#app .question .questionArea .content .feedbackArea .feedbackText ul li ul li {
  list-style: none;
}

#app
  .question
  .questionArea
  .content
  .feedbackArea
  .feedbackText
  ul
  li
  ul
  li:before {
  content: '-';
  margin-left: -1.5em;
}

#app .summary {
  min-height: 35em;
  padding-bottom: 1px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: left 2em center;
  background-size: 300px;
}

#app .summary .content {
  animation: fadeIn 333ms;
  margin: 50px 84px 64px 84px;
  background-repeat: no-repeat;
}

#app .summary .content.resource {
  background-image: none;
}

#app .summary .content.resource .contentTitle {
  animation: fadeIn 333ms;
  color: #1b365d;
  text-align: left;
  margin: 0px 0px 34px 0px;
  line-height: 1.5em;
  width: 450px;
}

#app .summary .content.resource .contentTitle h1 {
  /* font-family: 'avenri-light'; */
  color: #1b365d;
  font-size: 1.8em;
  line-height: 1.2em;
  width: 100%;
}

#app .summary .content .scoreArea {
  color: #231f20;
  text-align: left;
  padding: 0;
  line-height: 1.5em;
  width: 60%;
  float: right;
  margin-left: 275px;
  margin-right: 10px;
}

#app .summary .content .scoreArea p.status {
  color: #1b365d;
  /* font-family: 'avenri-black'; */
  font-size: 1.5em;
  margin-bottom: 10px;
}

#app .summary .content .scoreArea p.text {
  text-transform: uppercase;
  /* font-family: 'helvetica-rg'; */
  font-size: 1.1em;
  margin-bottom: 7px;
}

#app .summary .content .scoreArea p.score {
  width: 100%;
  color: #1b365d;
  /* font-family: 'helvetica-rg'; */
  font-size: 2.3em;
  line-height: 1em;
}

#app .summary .content .scoreArea .navButtons {
  margin-top: 47px;
}

#app .summary .content .summaryArea {
  color: #231f20;
  padding: 10px 0 10px 0;
}

#app .summary .content .summaryArea p {
  margin: 0;
  /* font-family: 'helvetica-rg'; */
  line-height: 1.25em;
}

#app .summary .content .summaryAreaAfter {
  color: #231f20;
  padding: 10px 0 0 0;
  margin-bottom: 28px;
  margin-top: -18px;
}

#app .summary .content .summaryAreaAfter p {
  margin: 0;
  /* font-family: 'helvetica-rg'; */
  line-height: 1.25em;
}

#app .summary .content .linksArea .linkArea {
  margin-bottom: 28px;
}

#app .summary .content .linksArea .linkArea .linkText {
  color: #231f20;
  margin: 0 10px 10px 0;
  font-weight: normal;
  font-size: 0.85em;
  line-height: 1.25em;
  text-align: left;
  /* font-family: 'helvetica-rg'; */
}

#app .summary .content .linksArea .linkArea ul {
  margin: 0 0 0 18px;
  list-style: disc;
  margin-left: 0;
}

#app .summary .content .linksArea .linkArea ul li {
  list-style: disc;
  margin-left: 1.5em;
  margin-bottom: 1px;
}

#app .summary .content .linksArea .linkArea ul li a {
  color: #231f20;
  /* font-family: 'helvetica-rg'; */
  border-bottom: #231f20 dotted 1px;
  font-size: 1em;
  line-height: 1.7em;
  padding-bottom: 3px;
}

#app .summary .content .linksArea .linkArea ul li a:hover,
#app .summary .content .linksArea .linkArea ul li a:focus {
  color: #1b365d;
  border-bottom: #1b365d dotted 1px;
}

#app .summary .content .emailArea {
  display: inline-block;
  background-color: #282828;
  color: #fff;
  line-height: 1.2em;
  margin: 0 1px 20px 1px;
}

#app .summary .content .emailArea .emailText {
  margin: 10px 10px 10px 10px;
}

#app .summary .content .emailArea .emailForm {
  width: 100%;
}

#app .summary .content .emailArea .emailForm form input {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: 0;
  height: 100%;
  width: 100%;
}

#app .summary .content .emailArea .emailForm form input.inputEmail {
  font-size: 1.25em;
  line-height: 1.25em;
}

#app .summary .content .emailArea .emailForm form input.inputSubmit {
  text-indent: -10000px;
}

#app .summary .content .emailArea .emailForm .emailFooter {
  margin: 10px 10px 10px 10px;
  color: #afafaf;
  background-color: #282a29;
}

#app .summary .content .emailArea .emailForm .emailFooter p {
  font-size: 0.85em;
  line-height: 1.25em;
}

#app .summary .content .emailArea .emailForm .emailFooter.popup,
#app .summary .content .emailArea .emailForm .emailFooter.sending {
  color: white;
}

#app .summary .content .emailArea .emailForm .emailFooter.failure,
#app .summary .content .emailArea .emailForm .emailFooter.error {
  color: #fc5821;
  background-color: #282a29;
}

#app .summary .content .resourceArea {
  color: #231f20;
  text-align: left;
  padding: 0;
  line-height: 1.5em;
  width: 100%;
  float: right;
  margin-top: 75px;
  margin-left: -445px;
}

#app .summary .content .resourceArea .moduleList {
  width: 100%;
  margin-bottom: 30px;
}

#app .summary .content .resourceArea .moduleList .moduleItem {
  width: 100%;
  margin-bottom: 0.5em;
}

#app .summary .content .resourceArea .moduleList .moduleItem h3 {
  width: auto;
  /* font-family: 'avenri-black'; */
  display: inline-block;
  font-size: 1.3em;
  line-height: 1em;
  color: #1b365d;
}

#app .summary .content .resourceArea .moduleList .moduleItem .startText {
  color: #1b365d;
  /* font-family: 'avenri-black'; */
  float: right;
  font-size: 1em;
  text-decoration: underline;
}

#app
  .summary
  .content
  .resourceArea
  .moduleList
  .moduleItem
  .startText.scoreText {
  text-decoration: none;
  /* font-family: 'avenri-black'; */
  font-size: 1em;
}

#app
  .summary
  .content
  .resourceArea
  .moduleList
  .moduleItem
  .startText.scoreText
  .big {
  font-size: 1.3em;
}

#app
  .summary
  .content
  .resourceArea
  .moduleList
  .moduleItem
  .linksArea
  .linkArea {
  margin-bottom: 15px;
  margin-top: 5px;
}

#app
  .summary
  .content
  .resourceArea
  .moduleList
  .moduleItem
  .linksArea
  .linkArea
  ul
  li {
  line-height: 1.5em;
}

#app
  .summary
  .content
  .resourceArea
  .moduleList
  .moduleItem
  .linksArea
  .linkArea
  ul
  li
  a {
  font-size: 1em;
  line-height: 1.3em;
}

#app .summary .content .resourceArea .moduleAfter {
  margin-bottom: 30px;
  line-height: 1.3em;
}

#app .summary .content .resourceArea .moduleAfter h1 {
  line-height: 1.3em;
}

#app .summary .content .resourceArea .navButtons {
  margin-top: 47px;
}

#app .summary .content .resourceArea .navButtons .right {
  width: 3em;
}

#app .summary .content .resourceArea .navButtons .nav {
  width: 30%;
}

#app .summary .content .resourceArea .navButtons .nav.leftButton {
  margin-right: 4.5%;
  float: left;
}

#app .summary .content .resourceArea .navButtons .nav.leftButton .center {
  text-align: left;
  text-transform: uppercase;
}

#app .summary .content .resourceArea .summaryButton .right {
  width: 3em;
}

#app .summary.module1 {
  background-image: url('../../../src/assets/images/intro1.png');
}

#app .summary.module1 .content {
  background-position: 8% top;
}

#app .summary.module2 {
  background-image: url('../../../src/assets/images/intro2.png');
}

#app .summary.module3 {
  background-image: url('../../../src/assets/images/intro4.png');
}

#app .summary.module4 {
  background-image: url('../../../src/assets/images/intro3.png');
}

#app .summary.module5 {
  background-image: url('../../../src/assets/images/intro5.png');
}

#app .summary.module6 {
  background-image: url('../../../src/assets/images/intro7.png');
}

#app .summary.module7 {
  background-image: url('../../../src/assets/images/intro6.png');
}

#app .summary.module8 {
  background-image: url('../../../src/assets/images/intro8.png');
}

#app .highLight .callout {
  padding: 20px 25px;
  background-color: D9E1E2;
  margin: 0 0 25px 0;
  position: relative;
  border-left: 5px solid #ffd38c;
  overflow: auto;
}

#app .highLight .callout p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

#app .highLight .callout li {
  display: list-item;
  text-align: -webkit-match-parent;
}

#app .highLight .callout ul {
  list-style-type: disc;
}

@media (max-width: 1024px) {
  #app .summary .content .resourceArea .navButtons {
    margin-top: 47px;
  }

  #app .summary .content .resourceArea .navButtons .nav {
    width: 50%;
    float: none;
    margin-top: 1em;
  }

  #app .summary .content .resourceArea .navButtons .nav.leftButton {
    width: 50%;
    float: none;
    margin-right: 0;
  }

  #app .summary .content .resourceArea .navButtons .nav.rightOnly .center {
    text-align: left;
  }
}

@media (max-width: 935px) {
  #app .barClicker .center .startText {
    font-size: 1.3em;
    line-height: 3.4em;
  }
}

@media (max-width: 890px) {
  #app .menu .modules .introText {
    width: 100%;
    float: none;
  }

  #app .menu .modules .important {
    width: 100%;
    float: none;
    margin-top: 30px;
  }

  #app .barClicker .center .startTime {
    font-size: 0.8em;
  }

  #app .barClicker .center .startText {
    font-size: 1.6em;
    line-height: 1.8em;
  }

  #app .intro .content {
    background-position: 0 top;
    background-image: none;
  }

  #app .intro .content .contentArea {
    width: 100%;
    float: none;
    margin-left: 10px;
    margin-top: -55px;
    padding: 0px;
  }

  #app .intro.module1 {
    background-image: none;
  }

  #app .intro.module2 {
    background-image: none;
  }

  #app .intro.module3 {
    background-image: none;
  }

  #app .intro.module4 {
    background-image: none;
  }

  #app .intro.module5 {
    background-image: none;
  }

  #app .intro.module5 .content {
    background-image: none;
  }

  #app .intro.module6 {
    background-image: none;
  }

  #app .intro.module7 {
    background-image: none;
  }

  #app .intro.module8 {
    background-image: none;
  }

  #app .question .questionArea {
    margin-top: 6em;
  }

  #app .question .questionArea .content {
    width: 100%;
  }

  #app .question .questionArea .imageArea {
    width: 100%;
    min-height: 16em;
    margin-bottom: 1em;
  }

  #app .question .questionArea .imageArea .image {
    width: 10em;
    /* height: 16em; */
    /* position: absolute; */
    /* margin-left: -8em; */
    /* left: 50%; */
    /* margin-top: -60px; */
    margin: 1em auto;
    display: block;
  }

  #app .summary .content {
    margin-top: 6em;
    background-image: none;
  }

  #app .summary .content .scoreArea {
    width: 100%;
    margin-left: 10px;
  }

  #app .summary .content .resourceArea {
    width: 100%;
  }

  #app .summary .content .resourceArea .moduleList {
    width: 100%;
  }

  #app .summary.module1 {
    background-image: none;
  }

  #app .summary.module2 {
    background-image: none;
  }

  #app .summary.module3 {
    background-image: none;
  }

  #app .summary.module4 {
    background-image: none;
  }

  #app .summary.module5 {
    background-image: none;
  }

  #app .summary.module5 .content {
    background-image: none;
  }

  #app .summary.module6 {
    background-image: none;
  }

  #app .summary.module7 {
    background-image: none;
  }

  #app .summary.module8 {
    background-image: none;
  }

  #app .intro .content {
    margin-top: 9em;
    background-position: 0 top;
  }

  #app .barClicker .center .startText {
    font-size: 1.3em;
    line-height: 3.4em;
  }
}

@media (max-width: 760px) {
  /* #app .question .questionArea .content .contentHolder {
    min-height: 0;
    padding: 6px;
    margin-top: -50px;
    margin-top: -50px;
  } */
  #app .summary .content.resource {
    margin-top: 50px;
  }

  #app .summary .content .resourceArea .moduleList .moduleItem {
    margin-top: 1.5em;
  }

  #app .summary .content .resourceArea .moduleList .moduleItem:first-child {
    margin-top: 0;
  }

  #app .summary .content .resourceArea .moduleList .moduleItem div h3 {
    width: 100%;
    margin-bottom: 0.5em;
  }

  #app .summary .content .resourceArea .moduleList .moduleItem div .startText {
    width: 100%;
  }

  #app .summary .content .resourceArea .summaryButton {
    width: 100%;
    min-width: 0;
  }

  #app .summary .content .resourceArea .navButtons {
    margin-top: 47px;
  }

  #app .summary .content .resourceArea .navButtons .nav {
    width: 100%;
    float: none;
  }

  #app .summary .content .resourceArea .navButtons .nav.leftButton {
    width: 100%;
    float: none;
  }

  #app .summary .content .resourceArea .navButtons .nav.rightOnly .center {
    text-align: left;
  }
}

@media (max-width: 700px) {
  #app .summary .content .resourceArea .summaryButton {
    width: 100%;
  }

  #app .summary .content .resourceArea .navButtons .barClicker.nav {
    width: 100%;
  }

  #app .summary .content .resourceArea .navButtons .barClicker.nav.leftButton {
    width: 100%;
  }
}

@media (max-width: 670px) {
  #app .summary .content .scoreArea .navButtons .nav {
    width: 47%;
  }
}

@media screen and (max-width: 685px) {
  #app .menu .modules ul {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
}

@media (max-width: 600px) {
  #app .menu .modules .important ul {
    display: grid;
    grid-template-columns: 1fr;
  }

  #app .layout .contentMenu.open {
    top: 3em;
    margin-left: 0;
    width: 100%;
  }

  #app .layout .contentAll {
    width: auto;
    margin-left: 0;
    border-left: none;
    border-right: 0;
    border-bottom: 0;
    padding: 2px;
  }

  #app .layout .contentAll.open {
    width: auto;
    padding: 0;
  }

  #app .menu .modules {
    padding: 18px 0 18px 0;
  }

  #app .menu .modules .important {
    margin-top: 15px;
  }

  #app .menu .modules .important ul li {
    width: 100%;
    height: auto;
    margin-bottom: 5px;
  }

  #app .menu .modules .important ul li a {
    background-image: none !important;
  }

  #app .menu .modules .introText h2 {
    font-size: 1.5em;
  }

  #app .menu .modules .introText .introBody {
    margin-top: 0.5em;
    font-size: 1em;
    padding: 4px;
  }

  #app .intro .content {
    margin: 5.5em 0 18px 0;
  }

  #app .intro .content .contentTitle {
    margin-bottom: 15px;
  }

  #app .intro .content .contentTitle h1 {
    font-size: 1.5em;
  }

  #app .intro .content .contentArea .contentText p {
    margin-bottom: 10px;
    font-size: 1em;
  }

  #app .intro .content .contentArea .contentText ul {
    margin-bottom: 10px;
    font-size: 1em;
  }

  #app .intro .content .buttonArea {
    margin-top: 15px;
  }

  #app .question .questionArea {
    margin: 5.5em 0 18px 0;
  }

  #app .question .questionArea .imageArea {
    min-height: 10em;
  }

  #app .question .questionArea .imageArea .image {
    /* height: 10em;
    margin-left: -5em; */
  }

  #app .question .questionArea .content ul li {
    margin-bottom: 5px;
  }

  #app .summary .content {
    margin: 5.5em 0 18px 0;
  }

  #app .summary .content ul li {
    margin-bottom: 5px;
  }

  #app .summary .content .linksArea .linkArea ul li a {
    font-size: 1em;
  }

  #app .summary .content.resource {
    margin-top: 18px;
  }

  #app .summary .content .resourceArea .moduleList .moduleItem h3 {
    font-size: 1.3em;
  }

  #app .summary .content .resourceArea .moduleList .moduleItem .startText {
    font-size: 1em;
  }

  #app
    .summary
    .content
    .resourceArea
    .moduleList
    .moduleItem
    .startText.scoreText {
    font-size: 1em;
  }

  #app .summary .content .resourceArea .navButtons {
    margin-top: 47px;
  }

  #app .summary .content .resourceArea .navButtons .nav {
    width: 50%;
    float: none;
  }

  #app .summary .content .resourceArea .navButtons .nav.leftButton {
    width: 50%;
    float: none;
  }

  #app .summary .content .resourceArea .navButtons .nav.rightOnly .center {
    text-align: left;
  }

  #app .barClicker .center .startText {
    line-height: 2.4em;
  }

  #app .barClicker.option .left {
    width: 3em;
  }
}

@media (max-width: 450px) {
  #app .barClicker .center.time {
    background-image: none;
  }

  #app .barClicker .center.time .startTime {
    display: none;
  }

  #app .barClicker .center.time.time5 {
    background-image: none;
  }

  #app .barClicker .center.time.time10 {
    background-image: none;
  }

  #app .barClicker .center.time.time15 {
    background-image: none;
  }

  #app .questionArea .content .navButtons .barClicker {
    float: none;
    width: 100%;
  }

  #app .summary .content .scoreArea .barClicker {
    width: 100%;
  }

  #app .summary .content .scoreArea .navButtons .barClicker {
    width: 100%;
    margin-bottom: 1em;
  }
}

@media (max-width: 767px) {
  .highlight .callout {
    padding: 10px 20px;
    margin: 10px;
  }
}

input ._placeholderStyle {
  color: #aaa;
  font-style: normal;
  text-overflow: ellipsis;
}

input.placeholder {
  color: #aaa;
  font-style: normal;
  text-overflow: ellipsis;
}

input::-webkit-input-placeholder {
  color: #aaa;
  font-style: normal;
  text-overflow: ellipsis;
}

input:-moz-placeholder {
  color: #aaa;
  font-style: normal;
  text-overflow: ellipsis;
}

input::-moz-placeholder {
  color: #aaa;
  font-style: normal;
  text-overflow: ellipsis;
}

input:-ms-input-placeholder {
  color: #aaa;
  font-style: normal;
  text-overflow: ellipsis;
}

.workplace-tooltip:after {
  content: attr(data-tooltip);
}

div.modules ul li a:hover {
  cursor: pointer;
}

a:hover {
  cursor: pointer;
}

body .ui.button.is-secondary {
  padding: 6px 32px;
}

.modal__box {
  padding-left: 0;
  padding-bottom: 0;
}
