.wages-fieldset {
  border: 4px solid #9bcbeb;
  margin: 0 0 1em;
  padding: 30px;
}

.form-check-label {
  display: block;
  padding-left: 30px;
}
.checkbox-inpput {
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  z-index: 1;
}
.wages-fieldset-title {
  padding: 30px;
}
.thank-you {
  padding: 30px;
}
.searchcontainer {
  align-items: left;
}

.option {
  align-items: center;
  justify-content: space-between;
  padding: 0px 0px 7px 0px;
}
.before-link {
  padding-top: 30px;
}
.clear-button {
  float: right;
}
.mandatory-message {
  padding-bottom: 30px;
}
.bookmark-img {
  height: 1.65em;
  margin-bottom: -0.53em;
  filter: brightness(0.85);
  opacity: 1.5;
}
