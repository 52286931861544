.sign {
    font-size: 25px;
  }
  .accordian {
    width: auto;
    border:none;
  }
  .accordian-header {
    font-weight: 600;
    font-size: 18px;
    color:  #024ca2;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 15px 15px 15px;
   
  
  }
  .accordian-body {
    padding: 15px 15px;
  }
  .text {
    padding: 5px 15px 5px 15px;
  }
  .resource-link {
    color:  #024ca2 !important;
   
  }