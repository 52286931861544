.redirect-overlay {
  position: fixed;
  display: block !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 1000;
}

.redirect-overlay p {
  margin-top: 15% !important;
}
