#enquiry-save-close-continue div {
  display: grid;
  grid-template-columns: 1fr 350px;
  border-bottom: 1px solid #888;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
@media screen and (max-width: 650px) {
  #enquiry-save-close-continue div {
    grid-template-columns: none;
  }
}
#enquiry-save-close-continue div span {
  line-height: 2em;
}

#enquiry-save-close-continue div .button {
  margin-bottom: 0;
}

#enquiry-save-close-continue div:last-child {
  border-bottom: none;
  margin: 0;
}

.enquiry-fieldset{
  border: 4px solid #9bcbeb;
  margin: 0 0 1em;
}
.enquiry-fieldset-legend{
font-weight: 1200;
}
.no-bullets {
  list-style-type: none;
  margin-left: -2.2em;
  line-height: 1.6;
 
 
}
.right {
  float: right;
}
.th-background {
background-color: #9bcbeb;
}
.tile-award {
  border: 4px solid #9bcbeb !important;
  padding: 20px;
}
.tile-award svg {
  fill: #000;
  height: 12px!important;
  width: 15px!important;
}
/*# sourceMappingURL=Enquiries.css.map */
 
 @media only screen and (max-width: 430px) {
  .ui.breadcrumb.is-linear .step .section {
    margin: 0!important;
  }
}