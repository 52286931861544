.float-right {
    float: right;
    width: 100%;
    height: 100%;
   
    
}
.cell-color {
    background: #e6e6e6;
}
.cell-width {
    width: 450px;

}
 .bookmarks svg {
    vertical-align: middle;
  }
  .bookmark-img {
    height: 1.65em;
    margin-bottom: -.53em;
    filter: brightness(.85);
    opacity: 1.5;

  }