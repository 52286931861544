footer.site-links a {
    width: 25%;
    display: inline-block;
    text-align: center;
  }

  @media screen and (max-width: 767px)
  {
  footer.site-links a {
      display: block;
      width: 100%;
  }
}