#abnSearch th {
  font-size: small;
}

#abnSearch td {
  font-size: small;
}

#abnSearch tr:hover td {
  background-color: #ffff99 !important;
  cursor: pointer;
}
