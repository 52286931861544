.wrapper {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
}
.content {
  display: flex;
  flex: 1;
}
.columns {
  display: flex;
  flex: 1;
}
main {
  flex: 1;
  order: 2;
}
.sidebar-first {
  width: 20%;
  order: 1;
}
.sidebar-second {
  width: 20%;
  order: 3;
}
ul.secondary-list {
  list-style-type: none;
  margin-top: 0;
  position: relative;
  top: -1rem;
}
