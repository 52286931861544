/* basic layout */
div#rfa-app .h2span {
  margin-top: 1em;
  font-size: 24px;
  font-weight: 400;
  font-style: normal;
}

div#rfa-app .help-button {
  border: none;
  outline: none;
  background: none;
  padding: 0px;
  color: #1b365d;
  height: 42px;
}

div#rfa-app .choice .button {
  width: 100%;
  padding-left: 16px;
}
div#rfa-app .choice input[type='radio'] {
  margin-right: 16px;
}

.choice {
  display: grid;
  grid-template-columns: 1fr 32px;
  gap: 16px;
  margin: 0 auto;
  max-width: 600px;
}

@media only screen and (max-width: 378px) {
  div#rfa-app .choice {
    max-width: 320px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
  div#rfa-app .choice {
    max-width: 320px;
  }
}
@media only screen and (-webkit-min-device-pixel-ratio: 2) {
  div#rfa-app .choice {
    max-width: 247px;
  }
}

div#rfa-app .text-center {
  text-align: center !important;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child {
  margin-bottom: revert;
}
