.waiting-overlay {
  position: fixed;
  display: block !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  opacity: 0.85;
  z-index: 9999;
}

.waiting-overlay p {
  margin-top: 25% !important;
}
