@media screen and (min-width: 320px) and (max-width: 480px) {
  .ui.selection.dropdown .menu>.item {
    max-width: 86vw;
  }

  .ui.selection.dropdown.feedbackTypes .menu>.item {
    max-width: 93vw;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .ui.selection.dropdown .menu>.item {
    max-width: 91vw;
  }
  .ui.selection.dropdown.feedbackTypes .menu>.item {
    max-width: 96vw;
  }
}

@media screen and (min-width: 769px) {
  .ui.selection.dropdown .menu>.item {
    max-width: 92vw;
  }
}

.anonymous-fieldset {
  border: 4px solid #9bcbeb;
  margin: 0 0 1em;
  padding: 30px;
}

.form-check-label {
  display: block;
  padding-left: 30px;
}
.checkbox-inpput {
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  z-index: 1;
}
.anonymous-fieldset-title {
  padding: 30px;
}
.outside-jurisdiction {
  padding: 30px;
}
.thank-you {
  padding: 30px;
}
.searchcontainer {
  align-items: left;
}

.option {
  align-items: center;
  justify-content: space-between;
  padding: 0px 0px 7px 0px;
}
.before-link {
  padding-top: 30px;
}
.clear-button {
  float: right;
}
.mandatory-message {
  padding-bottom: 30px;
}
.bookmark-img {
  height: 1.65em;
  margin-bottom: -.53em;
  filter: brightness(.85);
  opacity: 1.5;

}
@media (max-width: 767px) {
.content-wrapper {
    margin: 0;
    padding: 12px;
}
}
