.ui.dropdown .menu {
  background: #d9e1e2 !important;
}

.ui.selection.dropdown.award .menu > .item {
  width: 37rem;
}

@media screen and (min-width: 1025px) and (max-width: 1170px) {
  .ui.selection.dropdown.award .menu > .item {
    width: 30rem;
  }
}

@media screen and (min-width: 940px) and (max-width: 1024px) {
  .ui.selection.dropdown.award .menu > .item {
    width: 48rem;
  }
}

@media screen and (min-width: 850px) and (max-width: 939px) {
  .ui.selection.dropdown.award .menu > .item {
    width: 42rem;
  }
}

@media screen and (min-width: 790px) and (max-width: 850px) {
  .ui.selection.dropdown.award .menu > .item {
    width: 37rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 789px) {
  .ui.selection.dropdown.award .menu > .item {
    width: 33rem;
  }
}

@media screen and (min-width: 660px) and (max-width: 767px) {
  .ui.selection.dropdown.award .menu > .item {
    width: 37rem;
  }
}

@media screen and (min-width: 600px) and (max-width: 659px) {
  .ui.selection.dropdown.award .menu > .item {
    width: 33rem;
  }
}

@media screen and (min-width: 520px) and (max-width: 599px) {
  .ui.selection.dropdown.award .menu > .item {
    width: 27rem;
  }
}

@media screen and (min-width: 420px) and (max-width: 519px) {
  .ui.selection.dropdown.award .menu > .item {
    width: 20rem;
  }
}

@media screen and (min-width: 350px) and (max-width: 419px) {
  .ui.selection.dropdown.award .menu > .item {
    width: 15rem;
  }
}
